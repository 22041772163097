import React, { useEffect } from "react";
import { useState } from "react";
import { AccountApi } from "services/Api";
import toaster from "components/Toaster/Toaster";
import { Form, FormControl, Table } from "react-bootstrap";
import { Loader } from "components/SiteArt";

function TableHeadersTab(props) {
  const [headers, setHeaders] = useState(null);
  const [headersTitles, setHeadersTitles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { projId } = props;

  useEffect(() => {
    getHeaderSettingHandler();
  }, []);

  const getHeaderSettingHandler = () => {
    AccountApi.getHeaderSetting(projId).then((res) => {
      if (res.status === 200 && res.data.ResponseCode !== 404) {
        //let arr = [];
        let response;
        // if (typeof res.data.Body != "object") {
        response = JSON.parse(res.data.Body);

        setHeaders(response);
        response = response
          .filter((obj) => obj.keyValue)
          .filter((obj) => obj.Ordering != -1)
          .sort((a, b) => a.Ordering - b.Ordering)
          .map((ob) => {
            return ob.lables;
          });
        setHeadersTitles(response);
        setIsLoading(false);
      } else {
        toaster.show({ message: res.data.Message, intent: "danger" });
        setIsLoading(false);
      }
    });
  };

  const postHeaderSettingHandler = () => {
    const headerPayload = headers.map((x, i) => {
      return {
        key: x.key,
        keyValue: headersTitles.indexOf(x.lables) != -1,
        Ordering: headersTitles.indexOf(x.lables),
        lables: x.lables,
      };
    });

    var payload = {
      ProjectId: projId,
      HeadersJson: JSON.stringify(headerPayload),
    };
    AccountApi.postHeaderSettingApi(payload)
      .then((res) => {
        if (res.status === 200) {
          toaster.show({ message: res.data.Message, intent: "success" });
        } else {
          toaster.show({ message: res.data.Message, intent: "danger" });
        }
      })
      .catch((err) => toaster.show({ message: err.Message, intent: "danger" }));
  };

  const checkedHandler = (e) => {
    const updatedHeaders = headers.map((x) => {
      if (x.key == e.target.id) {
        x.keyValue = e.target.checked;
        if (e.target.checked) {
          setHeadersTitles([...headersTitles, x.lables]);
        } else {
          const headerTitle = headersTitles.filter((str) => str != x.lables);
          setHeadersTitles([...headerTitle]);
        }
      }
      return x;
    });
    setHeaders(updatedHeaders);
  };

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="row my-0 mx-0">
            {headersTitles && (
              <Table
                striped
                bordered
                hover
                className="tabTable"
                id="headersTable"
              >
                <thead>
                  <tr>
                    {headersTitles.map((value) => {
                      return <th>{value}</th>;
                    })}
                  </tr>
                </thead>
              </Table>
            )}
            <Table
              striped
              bordered
              hover
              className="tabTable"
              id="headersTable"
            >
              <tbody>
                {headers &&
                  headers.map((obj, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <Form.Check
                            type="checkbox"
                            checked={obj.keyValue}
                            onChange={checkedHandler}
                            label={obj.lables}
                            id={obj.key}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>

            {/* {headers &&
              headers.map((obj) => {
                return (
                  <Form.Check
                    type="checkbox"
                    checked={obj.value}
                    onChange={checkedHandler}
                    label={obj.key}
                    id={obj.key}
                  />
                );
              })} */}
          </div>
          {headers && (
            <button
              type="button"
              onClick={postHeaderSettingHandler}
              className="btn btn-primary mt-4"
            >
              Submit
            </button>
          )}
        </>
      )}
    </>
  );
}

export default TableHeadersTab;
