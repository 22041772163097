import React from "react";
import { NavLink } from "react-router-dom";
import { SvgIcon } from "components/SiteArt";
import { SITE_PATHS } from "config";
import AuthorizationService from "services/AuthorizationService";
import prescreeningLogo from "assets/images/Profilingstar-Cropped.png";
import iconAdminProfile from "assets/images/icons/icon-admin-profile.svg";

// import Logo from "assets/images/logo/Prelogo.png";
import { connect } from "react-redux";
import { FiLogOut } from "react-icons/fi";

import { get as lodashGet } from "lodash";
import { store } from "services/Redux";
var isBase64 = require("is-base64");

const {
  HOME_PAGE_PATH,
  ADD_PROJECT_PAGE_PATH,
  PROFILE_EDIT_PAGE_PATH,
  SUPER_ADMIN_PANEL_PAGE_PATH,
  // ADMIN_PANEL_PAGE_PATH,
} = SITE_PATHS;

const USER_ROLES = {
  SUPER_ADMIN: "Super Admin",
  PROJECT_ADMIN: "Project Admin",
  SUPER_VISIOR: "Supervisor",
  CHECKER: "Checker",
  MAKER: "Maker",
};
const SIDEBAR_NAV_OPTIONS = [
  {
    title: "Home",
    icon: <SvgIcon className="nav-link__icon" icon="iconHome" />,
    linkTo: HOME_PAGE_PATH,
    roles: Object.values(USER_ROLES),
  },

  {
    title: "Admin Panel",
    icon: <SvgIcon className="nav-link__icon" icon="iconAdminPanel" />,
    linkTo: SUPER_ADMIN_PANEL_PAGE_PATH,
    roles: [USER_ROLES.SUPER_ADMIN],
  },

  {
    title: "Add Project",
    icon: <SvgIcon className="nav-link__icon" icon="iconAddProject" />,
    linkTo: ADD_PROJECT_PAGE_PATH,
    roles: [USER_ROLES.SUPER_ADMIN],
  },
];

class AppSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSidebarLocked: false,
    };
  }

  toggleIsSidebarLocked = (state) => {
    this.setState({ isSidebarLocked: state });
  };

  handleLogout = (e) => {
    e.preventDefault();
    AuthorizationService.logout();
  };

  render() {
    const { isSidebarLocked } = this.state;
    const { userProfilePicture, userProfileConstant } = this.props;
    const storeData = store.getState();
    const userStoreRoleInfo = lodashGet(
      storeData,
      "user.userData.userInfo.MainRole.RoleName",
      ""
    );

    return (
      <div
        className={`site-navigation-panel uncopyable${
          isSidebarLocked ? " is-locked" : ""
        }`}
      >
        <div className="brand-logo-container">
          <img
            className="brand-logo-img"
            src={prescreeningLogo}
            alt="Profiling Star"
          />{" "}
          <span className="nav-link__title  nav-link__title--brand-name logo-white-clor">
            Profiling Star
            {/* <br /> Star */}
          </span>
        </div>
        <div className="site-navigation-panel-lock__container">
          <input
            type="checkbox"
            id="site-navigation-panel-lock__toggle-input"
            className="site-navigation-panel-lock__toggle-input"
            onClick={(e) => this.toggleIsSidebarLocked(e.target.checked)}
          />
          <label
            className="site-navigation-panel-lock__toggle-input-label"
            htmlFor="site-navigation-panel-lock__toggle-input"
          >
            <span
              className={`site-navigation-panel-lock__icon-unlock mdi mdi-lock-open-variant${
                isSidebarLocked ? " hidden" : ""
              }`}
            />
            <span
              className={`site-navigation-panel-lock__icon-lock mdi mdi-lock${
                !isSidebarLocked ? " hidden" : ""
              }`}
            />
          </label>
        </div>

        <nav className="site-navigation-panel__nav">
          <ul>
            {SIDEBAR_NAV_OPTIONS.filter((nav) =>
              nav.roles.includes(userStoreRoleInfo)
            ).map((navItem, i) => {
              return (
                <li key={i} data-toggle="tooltip" title={navItem.title}>
                  <NavLink className="nav-link" to={navItem.linkTo || "#"}>
                    <span className="nav-link__icon-container">
                      {navItem.icon}
                    </span>
                    <span className="nav-link__title">{navItem.title}</span>
                  </NavLink>
                </li>
              );
            })}

            <li className="mt-auto" data-toggle="tooltip" title="Profile">
              <NavLink
                className="nav-link nav-link--profile"
                to={PROFILE_EDIT_PAGE_PATH}
              >
                <span className="nav-link__icon-container nav-link__icon-container--profile">
                  <img
                    className="nav-link__icon p-0"
                    src={
                      userProfilePicture && isBase64(userProfilePicture)
                        ? `data:image/png;base64,${userProfilePicture}`
                        : iconAdminProfile
                    }
                    alt="user-profile"
                  />
                </span>
                <span className="nav-link__title">Profile</span>
              </NavLink>
            </li>

            <li data-toggle="tooltip" title="Logout">
              <NavLink
                className="nav-link nav-link__logout"
                to="/login"
                title="Logout"
                onClick={this.handleLogout}
              >
                <span className="nav-link__icon-container">
                  <SvgIcon className="nav-link__icon" icon="iconLogout" />
                </span>
                <span className="nav-link__title">Logout</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfilePicture: state.user.userData?.userInfo?.UserImage,
  userProfileConstant: state.user.userProfileConstant,
});

export default connect(mapStateToProps)(AppSidebar);
