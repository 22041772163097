import Api from "./ApiWrapper";
import {
  USER_DATA_ENDPOINT,
  FORGOT_PASSWORD_ENDPOINT,
  UPDATE_USER_DATA_ENDPOINT,
  USER_PROJECTS_ENDPOINT,
  USER_ALLOCATION_ENDPOINT,
  ALLOCATION_FILTER,
  DELIVERY_FILTER,
  STATUS_LIST,
  USER_MASTERFILTER_ENDPOINT,
  USERS_LIST_ENDPOINT,
  ROLE_OPTIONS_ENDPOINT,
  ADMIN_USERS_ROLE_MAPPING_ENDPOINT,
  ADMIN_ADD_USER_ENDPOINT,
  ADMIN_DECIDE_USER_ROLE_ENDPOINT,
  ADMIN_PROJECT_LIST_ENDPOINT,
  ADMIN_PROJECT_SCHEMA_ENDPOINT,
  ADD_PROJECT_ENDPOINT,
  FETCH_PROJECT_DETAILS_ENDPOINT,
  UPDATE_PROJECT_DETAILS_ENDPOINT,
  FETCH_LISTING_HISTORY_ENDPOINT,
  UPLOAD_ALLOCATION_ENDPOINT,
  USER_PROFILE_IMAGE_ENDPOINT,
  UPDATE_USER_PROFILE_IMAGE_ENDPOINT,
  REFRESH_TOKEN,
  CONFIRM_EMAIL,
  SSO_LOGIN_ENDPOINT,
  SSO_CHANGE_PASSWORD_ENDPOINT,
  RESET_FORGOT_PASSWORD_ENDPOINT,
  CALENDER_FILTER,
  ALLOCATE_USER,
  DOWNLOAD_FILE,
  REOPEN_CASE,
  FETCH_DELIVERY_DATA,
  DELETE_BY_STATUS,
  ADD_PLUGIN,
  UPLOAD_PROFILE,
  USER_NAMES_LIST,
  FINAL_SUBMIT,
  GET_JSON_SCHEMA,
  DOWNLOAD_SETTINGS,
  UPDATE_DOWNLOAD_SETTINGS,
  SKIP_CHECKER_MAKER,
  ONHOLD_STATUS,
  GET_API_INTEGRATION,
  POST_API_INTEGRATION,
  GET_API_SETTINGS,
  DELIVERY_DATA_API,
  HEADER_SETTING_API,
  POST_HEADER_SETTING_API,
  SAMPLE_FILE_DOWNLOAD_API,
  UPDATE_PASSWORD_API,
  GET_LISTING_API,
  GET_REMEDIATION_DATA_API,
  GET_REMEDIATION_HEADER_API,
  INSERT_LISTING_UPLOAD_API,
  INSERT_REMEDIATION_API,
  INSERT_REMEDIATION_HEADER_API,
  GET_MIGRATION_API,
  INSERT_MIGRATIONS_API,
  GET_ALL_API,
} from "./ApiEndpoints";

//LOGIN
function login(UserName, Password) {
  const body = {
    UserName,
    Password,
  };

  return Api.post(`${SSO_LOGIN_ENDPOINT}`, body);
}

//sample file download
function downloadSampleFile(ProjectVal) {
  const queryParams = `?projectIDd=${ProjectVal}`;
  return Api.get(`${SAMPLE_FILE_DOWNLOAD_API}${queryParams}`, {}, "blob");
}

//To Get User Profile Details
function fetchAccountDetails(userID) {
  const queryParams = `${userID}`;
  return Api.get(`${USER_DATA_ENDPOINT}${queryParams}`);
}

//USER PROJECTS LIST
function userProjectsDetails(userID) {
  const queryParams = `${userID}`;
  return Api.get(`${USER_PROJECTS_ENDPOINT}${queryParams}`);
}

//USER ALLOCATION ENDPOINT
function userAllocationData(ProjectId, UserId, RoleId, pageSize, pageNumber) {
  const queryParams = `?ProjectId=${ProjectId}&UserId=${UserId}&RoleId=${RoleId}&pageSize=${pageSize}&pageNumber=${pageNumber}`;
  return Api.get(`${USER_ALLOCATION_ENDPOINT}${queryParams}`);
}
//ALLOCATION FILTER
function allocationFilter(ProjectId, UserId, RoleId) {
  const queryParams = `?projectId=${ProjectId}&UserId=${UserId}&RoleId=${RoleId}`;
  return Api.get(`${ALLOCATION_FILTER}${queryParams}`);
}
//DELIVERY FILRER
function deliveryFilter(ProjectId, UserId, RoleId) {
  const queryParams = `?projectId=${ProjectId}&UserId=${UserId}&RoleId=${RoleId}`;
  return Api.get(`${DELIVERY_FILTER}${queryParams}`);
}

function changeStatus(ProjectId, RoleId) {
  const queryParams = `?projectId=${ProjectId}&RoleId=${RoleId}`;
  return Api.get(`${STATUS_LIST}${queryParams}`);
}

function getUserName(ProjectId, RoleId) {
  const queryParams = `?projectId=${ProjectId}&RoleId=${RoleId}`;
  return Api.get(`${USER_NAMES_LIST}${queryParams}`);
}

//MASTER SEARCH
function masterFilterApi(body) {
  return Api.post(`${USER_MASTERFILTER_ENDPOINT}`, body);
}

function allocateUser(body) {
  return Api.put(`${ALLOCATE_USER}`, body);
}

function calenderDateFilter(body) {
  return Api.post(`${CALENDER_FILTER}`, body);
}
//Download File
function downloadFile(body) {
  return Api.post(`${DOWNLOAD_FILE}`, body, {
    "Content-Type": "application/zip",
    redirect: "follow",
  });
}
// delivered page data
function getDelivery(projectId, pageSize, pageNumber) {
  const queryParams = `${projectId}&pageSize=${pageSize}&pageNumber=${pageNumber}`;
  return Api.get(`${FETCH_DELIVERY_DATA}?projectId=${queryParams}`);
}

function changePasswordSSO(body) {
  return Api.post(`${SSO_CHANGE_PASSWORD_ENDPOINT}`, body);
}

function updatePassword(body) {
  return Api.post(`${UPDATE_PASSWORD_API}`, body);
}

function finalSubmit(body) {
  return Api.post(`${FINAL_SUBMIT}`, body);
}

function addPlugin(body) {
  return Api.post(`${ADD_PLUGIN}`, body);
}

function UploadProfile(body) {
  return Api.post(`${UPLOAD_PROFILE}`, body);
}

function resetForgotPassword(body) {
  return Api.post(`${RESET_FORGOT_PASSWORD_ENDPOINT}`, body);
}

function updateAccountDetails(data) {
  return Api.put(UPDATE_USER_DATA_ENDPOINT, data);
}

function fetchUserProfileImage() {
  return Api.get(USER_PROFILE_IMAGE_ENDPOINT);
}

function updateUserProfileImage(data) {
  return Api.post(UPDATE_USER_PROFILE_IMAGE_ENDPOINT, data);
}

function forgotPassword(recoveryEmail) {
  const queryParams = `${recoveryEmail}`;
  return Api.get(`${FORGOT_PASSWORD_ENDPOINT}?emailId=${queryParams}`);
}

function refreshToken(token) {
  return Api.post(REFRESH_TOKEN, token);
}

function fetchConfirmEmail() {
  return Api.get(CONFIRM_EMAIL);
}

//---------------------*****ADMIN PANEL PAGE*****-------------------------//

function RoleOptions() {
  return Api.get(ROLE_OPTIONS_ENDPOINT);
}
function AdminUserRoles(projectId) {
  const queryParams = `${projectId}`;
  return Api.get(
    `${ADMIN_USERS_ROLE_MAPPING_ENDPOINT}?projectid=${queryParams}`
  );
}
function RoleAssignByAdmin(Body) {
  return Api.post(ADMIN_DECIDE_USER_ROLE_ENDPOINT, Body);
}

function fetchAdminProjects(userID) {
  const queryParams = `${userID}`;
  return Api.get(`${ADMIN_PROJECT_LIST_ENDPOINT}?userId=${queryParams}`);
}

function registerUser(registerData) {
  return Api.post(ADMIN_ADD_USER_ENDPOINT, registerData);
}

function projectSchema(data) {
  return Api.post(ADMIN_PROJECT_SCHEMA_ENDPOINT, data);
}
//---------------------*****ADD PROJECT PAGE*****-------------------------//
function addProject(registerData) {
  return Api.post(ADD_PROJECT_ENDPOINT, registerData);
}
//---------------------*****EDIT PROJECT PAGE*****-------------------------//

function fetchProjectDetails(projectId) {
  const queryParams = `${projectId}`;
  return Api.get(`${FETCH_PROJECT_DETAILS_ENDPOINT}?projectId=${queryParams}`);
}
function updateProjectDetails(updatedData) {
  return Api.post(UPDATE_PROJECT_DETAILS_ENDPOINT, updatedData);
}
//UPLOAD ALLOCATION PAGE
function UploadAllocation(uploadData) {
  return Api.post(UPLOAD_ALLOCATION_ENDPOINT, uploadData);
}
function fetchListingHistory(projectId) {
  const queryParams = `${projectId}`;
  return Api.get(`${FETCH_LISTING_HISTORY_ENDPOINT}?projectId=${queryParams}`);
}

//UPLOAD PAGE
function fetchUsersData(projectId) {
  const queryParams = `${projectId}`;
  return Api.get(`${USERS_LIST_ENDPOINT}?projectId=${queryParams}`);
}

//assign and allocate user
function fetchAllUsersBasedRole(projectId, USERS_ENDPOINT) {
  const queryParams = `${projectId}`;
  return Api.get(`${USERS_ENDPOINT}?projectId=${queryParams}`);
}

//reopen case
function reopenCase(body) {
  return Api.post(`${REOPEN_CASE}`, body);
}

function deleteStatus(Body) {
  return Api.delete(`${DELETE_BY_STATUS}`, Body);
}

function getJsonSchema(projectId) {
  const queryParams = `${projectId}`;
  return Api.get(`${GET_JSON_SCHEMA}?projectId=${queryParams}`);
}

//api integration function get call

function apiIntegrationSetting(ProjectId) {
  const queryParams = `?projectId=${ProjectId}`;
  return Api.get(`${GET_API_INTEGRATION}${queryParams}`);
}

function getApiEnabledSetting(ProjectId, Status, Get) {
  const queryParams = `?projectId=${ProjectId}&status=${Status}&get=${Get}`;
  return Api.get(`${GET_API_SETTINGS}${queryParams}`);
}

function postAPIIntegration(body) {
  return Api.post(`${POST_API_INTEGRATION}`, body);
}

function fetchAllDownloadSetting(projectId) {
  const queryParams = `${projectId}`;
  return Api.get(`${DOWNLOAD_SETTINGS}?projectId=${queryParams}`);
}
function UploadDownloadSetting(body) {
  return Api.post(`${UPDATE_DOWNLOAD_SETTINGS}`, body);
}

function skipCheckerMaker(body) {
  return Api.post(`${SKIP_CHECKER_MAKER}`, body);
}

function onHoldChange(body) {
  return Api.post(`${ONHOLD_STATUS}`, body);
}

function apiDeliveryData(body) {
  return Api.post(`${DELIVERY_DATA_API}`, body);
}

function getHeaderSetting(ProjectId) {
  const queryParams = `?projectId=${ProjectId}`;
  return Api.get(`${HEADER_SETTING_API}${queryParams}`);
}

function postHeaderSettingApi(body) {
  return Api.post(`${POST_HEADER_SETTING_API}`, body);
}
function getListingUploadApi(projectID){
  const queryParams = `?projectId=${projectID}`;
  return Api.get(`${GET_LISTING_API}${queryParams}`)
}
function getALLApi(projectID){
  const queryParams = `?projectId=${projectID}`;
  return Api.get(`${GET_ALL_API}${queryParams}`)
}
function getRemediationDataApi(projectID){
  const queryParams = `?projectId=${projectID}`;
  return Api.get(`${GET_REMEDIATION_DATA_API}${queryParams}`)
}
function getRemediationHeaderApi(projectID){
  const queryParams = `?projectId=${projectID}`;
  return Api.get(`${GET_REMEDIATION_HEADER_API}${queryParams}`)
}
function getMigraionApi(projectID){
  const queryParams = `?projectId=${projectID}`;
  return Api.get(`${GET_MIGRATION_API}${queryParams}`)
}
function postLISTINGAPI(body) {
  return Api.post(`${INSERT_LISTING_UPLOAD_API}`, body);
}
function postMigrationAPI(body) {
  return Api.post(`${INSERT_MIGRATIONS_API}`, body);
}
function postRemediationAPI(body) {
  return Api.post(`${INSERT_REMEDIATION_API}`, body);
}
function postRemediationHeaderAPI(body) {
  return Api.post(`${INSERT_REMEDIATION_HEADER_API}`, body);
}
function amlRegulations(search,datefrom,dateto,country,pageNumber,sizePerPage) {
  const queryParams = `?name=${search}&countrycode=${country}&start_date=${datefrom}&end_date=${dateto}&page_no=${pageNumber}&page_limit=${sizePerPage}`;
  return Api.get(`https://listingprodapiv2.amlpenalties.com/profiling/regulation_get${queryParams}`);
}
function AMLCountriesList(){
  return Api.get(`https://clientprodapiv2.amlpenalties.com/api/v1/core/countries/list?region=`);
}
function AMLregulationsHeaders(){
  return Api.get(`https://listingprodapiv2.amlpenalties.com/profiling/regulation_headers_get`);
}
function AMLRegulationsMigrate (body){
  return Api.post('https://listingprodapiv2.amlpenalties.com/profiling/regulation_migrate',body);
}
const AccountApi = {
  amlRegulations,
  AMLRegulationsMigrate,
  AMLCountriesList,
  AMLregulationsHeaders,
  login,
  fetchAccountDetails,
  userProjectsDetails,
  userAllocationData,
  masterFilterApi,
  forgotPassword,
  updateAccountDetails,
  fetchUserProfileImage,
  updateUserProfileImage,
  //ADMIN PANEL
  RoleOptions,
  AdminUserRoles,
  registerUser,
  RoleAssignByAdmin,
  fetchAdminProjects,
  projectSchema,

  //ADD PROJECT
  addProject,
  //EDIT PROJECT
  fetchProjectDetails,
  updateProjectDetails,
  fetchListingHistory,
  UploadAllocation,
  refreshToken,
  fetchConfirmEmail,
  //loginSSO,
  changePasswordSSO,
  resetForgotPassword,

  //MEDIENA

  //UPLOAD
  fetchUsersData,
  //Allocation Page
  calenderDateFilter,
  allocateUser,
  //download files
  downloadFile,
  getDelivery,
  //fetch all user based on role
  fetchAllUsersBasedRole,
  reopenCase,
  addPlugin,
  UploadProfile,
  allocationFilter,
  deliveryFilter,
  changeStatus,
  getUserName,
  deleteStatus,
  finalSubmit,
  getJsonSchema,
  fetchAllDownloadSetting,
  UploadDownloadSetting,
  skipCheckerMaker,
  onHoldChange,
  apiIntegrationSetting,
  postAPIIntegration,
  getApiEnabledSetting,
  apiDeliveryData,
  getHeaderSetting,
  postHeaderSettingApi,
  downloadSampleFile,
  updatePassword,
  getListingUploadApi,
  postLISTINGAPI,
  getRemediationDataApi,
  postRemediationAPI,
  getRemediationHeaderApi,
  postRemediationHeaderAPI,
  getMigraionApi,
  postMigrationAPI,
  getALLApi
};

export default AccountApi;
