import { Loader } from "components/SiteArt";
import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router";
import Switch from "react-switch";
import { AccountApi } from "services/Api";
function Downloadsetup(props) {
  const [isLoading, setisLoading] = useState(true);
  const { projectID } = useParams();
  const [currentSetting, setCurrentSetting] = useState();
  const [disableCheck, setDisableAll] = useState();
  const projId = projectID ? projectID : props.projectID;

  useEffect(() => {
    getUserAllocationData();
  }, []);

  const getUserAllocationData = () => {
    AccountApi.fetchAllDownloadSetting(projId).then((res) => {
      if (res.status === 200) {
        setisLoading(false);
        setCurrentSetting(res.data.Body);
        setDisableAll(!res.data.Body.Enabled);
      }
    });
  };
  const getDownloadsetup = (name, e) => {
    let payload = {
      ProjectId: parseInt(projId),
      Csv: Csv,
      Excel: false,
      Pdf: Pdf,
      Xml: Xml,
      Json: Json,
      Enabled: Enabled,
    };
    payload[name] = e;
    AccountApi.UploadDownloadSetting(payload).then((res) => {
      if (res.status === 200) {
        getUserAllocationData();
      }
    });
  };
  const { Enabled, Csv, Excel, Pdf, Xml, Json } = currentSetting || {};
  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="row mx-0">
            <div className="d-flex align-items-center" id="example">
              Enable/Disable
              <Switch
                onChange={(e) => getDownloadsetup("Enabled", e)}
                checked={Enabled}
                className="react-switch1"
                offHandleColor="#808080"
                onHandleColor="#0000FF"
              />
            </div>
          </div>
          <div>
            <label className="d-flex align-items-center">
              <span className="example">CSV</span>
              <Switch
                onChange={(e) => getDownloadsetup("Csv", e)}
                checked={Csv}
                className="react-switch2"
                disabled={disableCheck}
                offHandleColor="#808080"
                onHandleColor="#0000FF"
              />
            </label>
          </div>
          {/* <div >
        <label className="d-flex align-items-center">
          <span className="example">Excel</span>
          <Switch
            onChange={e=>getDownloadsetup('Excel',e)}
            checked={Excel}
            className="react-switch3"
            disabled={disableCheck}
            offColor="#808080"
          />
        </label>
      </div> */}
          {/* <div >
        <label className="d-flex align-items-center">
          <span className="example">PDF</span>
          <Switch
            onChange={e=>getDownloadsetup('Pdf',e)}
            checked={Pdf}
            className="react-switch4"
            disabled={disableCheck}
            offHandleColor="#808080"
            onHandleColor="#0000FF"
          />
        </label>
      </div> */}
          <div>
            <label className="d-flex align-items-center">
              <span className="example">XML</span>
              <Switch
                onChange={(e) => getDownloadsetup("Xml", e)}
                checked={Xml}
                className="react-switch5"
                disabled={disableCheck}
                offHandleColor="#808080"
                onHandleColor="#0000FF"
              />
            </label>
          </div>
          <div>
            <label className="d-flex align-items-center">
              <span className="example">JSON</span>
              <Switch
                onChange={(e) => getDownloadsetup("Json", e)}
                checked={Json}
                className="react-switch6"
                disabled={disableCheck}
                offHandleColor="#808080"
                onHandleColor="#0000FF"
              />
            </label>
          </div>
        </>
      )}
    </>
  );
}
export default Downloadsetup;
