import React, { PureComponent } from "react";
import { Form } from "react-bootstrap";
import { get as lodashGet } from "lodash";
// import { Dropdown } from "react-bootstrap";
import PubSub from "pubsub-js";
import scrollIntoView from "scroll-into-view-if-needed";
// import { FILTER_DATE } from '../../../constants/pubsub';
// import { DATE_FORMAT } from '../../../constants/global';
// import DatePicker from "react-datepicker";

import { DateRangePicker } from "react-date-range";

import {
  addYears,
  startOfMonth,
  endOfMonth,
  // addMonths,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  startOfYesterday,
  startOfTomorrow,
  startOfToday,
  endOfYesterday,
  startOfQuarter,
  endOfQuarter,
  format,
} from "date-fns";

// const DATE_FORMAT = "yyyy-MM-dd";
const FILTER_DATE = "fromDate";

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfYear: startOfYear(new Date()),
  endOfYear: endOfYear(new Date()),
  startOfQuarter: startOfQuarter(new Date()),
  endOfQuarter: endOfQuarter(new Date()),
  today: startOfToday(new Date()),
  eyesterday: endOfYesterday(),
  yesterday: startOfYesterday(),
  startOfTomorrow: startOfTomorrow(),
  last7days: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
  last15days: new Date(Date.now() - 15 * 24 * 60 * 60 * 1000),
  last30days: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
};

// class DateFilter extends PureComponent {
//   constructor(props) {
//     super(props);

//     this.state = {
//       showDateSelectPanel: false,
//     };
//   }

class DateFilter extends PureComponent {
  constructor(props) {
    super(props);
    var startminDate = defineds.startOfTomorrow;
    var endmaxDate = addYears(defineds.startOfTomorrow, 2);
    var getDaysArray = function (startminDate, endmaxDate) {
      for (
        var a = [], d = new Date(startminDate);
        d <= endmaxDate;
        d.setDate(d.getDate() + 1)
      ) {
        a.push(new Date(d));
      }
      return a;
    }; //for creating array for disable dates
    this.state = {
      showDateSelectPanel: false,
      formData: [],
      temp: 0,
      maxrange: getDaysArray(startminDate, endmaxDate),
    };
  }

  // handleSelect = (date) => {
  //   this.setState({ formData: [date.selection] });
  //   PubSub.publish(FILTER_DATE, {
  //     startDate: date.selection.startDate,
  //     endDate: date.selection.endDate,
  //   });

  //   this.setState({
  //     showDate:
  //       format(date.selection.startDate, "dd MMM yyyy") +
  //       " - " +
  //       format(date.selection.endDate, "dd MMM yyyy"),
  //   });

  //   if (date.selection.startDate !== date.selection.endDate) {
  //     this.setState({ toggle: !this.state.toggle });
  //   }
  // };

  handleSelect = (date) => {
    // this.setState({ formData: [date.selection] });
    PubSub.publish(FILTER_DATE, {
      startDate: date.selection.startDate,
      endDate: date.selection.endDate,
    });

    this.setState({
      showDate:
        format(date.selection.startDate, "dd MMM yyyy") +
        " - " +
        format(date.selection.endDate, "dd MMM yyyy"),
    });

    // if (date.selection.startDate !== date.selection.endDate) {
    //   this.setState({ toggle: !this.state.toggle });
    // }
  };

  // toggleDateSelectPanel = (state) => {
  //   const { showDateSelectPanel } = this.state;
  //   let newState = !showDateSelectPanel;

  //   if (state === true || state === false) {
  //     newState = state;
  //   }

  //   this.setState({ showDateSelectPanel: newState });
  // };

  toggleDateSelectPanel = (state) => {
    const { showDateSelectPanel } = this.state;
    let newState = !showDateSelectPanel;

    if (state === true || state === false) {
      newState = state;
    }

    this.setState({ showDateSelectPanel: newState });
    if (this.props.special && newState) {
      // setTimeout(() => {
      //   document.getElementById("in-focus").scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'end' });

      // }, 10);
      const node = document.getElementById("in-focus");
      setTimeout(() => {
        scrollIntoView(node, {
          behavior: "smooth",
          block: "start",
          scrollMode: "always",
        });
      }, 10);
    }
  };

  // handleOnChange = (range) => {
  //   let { onChange } = this.props;

  //   const endDate = lodashGet(range, "selection.endDate", null);
  //   const startDate = lodashGet(range, "selection.startDate", null);

  //   if (!(onChange instanceof Function)) {
  //     onChange = () => {};
  //   }

  //   if (endDate !== startDate) {
  //     this.toggleDateSelectPanel(false);
  //   }

  //   onChange(range);
  // };

  handleOnChange = (range) => {
    let { onChange } = this.props;
    this.setState({ formData: range });
    const endDate = lodashGet(range, "selection.endDate", null);
    const startDate = lodashGet(range, "selection.startDate", null);

    if (!(onChange instanceof Function)) {
      onChange = () => {};
    }

    if (endDate !== startDate) {
      this.toggleDateSelectPanel(false);
      this.setState({ formData: range }, () => {
        onChange(this.state.formData, true);
      }); //True is passed when calendar is closed to hit the api
    }
    onChange(range, false); //this is only to show date selected
  };

  render() {
    const { showDateSelectPanel } = this.state;
    const { className, fullWidth } = this.props;
    let { startDate, endDate, placeholder } = this.props;

    let visibleDate = placeholder || "Select Date Range";

    if (startDate || endDate) {
      visibleDate = `${
        startDate ? format(startDate, "dd MMM yyyy") : "..."
      } to ${endDate ? format(endDate, "dd MMM yyyy") : "..."}`;
    }

    const defaultStartDate = new Date();
    const defaultEndDate = new Date(); // today

    const ranges = [
      {
        startDate: startDate || defaultStartDate,
        endDate: endDate || defaultEndDate,
        key: "selection",
      },
    ];

    return (
      <div
        className={`date-range-picker${className ? " " + className : ""}${
          fullWidth ? " full-width" : ""
        }`}
      >
        <button
          type="button"
          className="btn btn-primary btn-filter date-range-picker__toggle-button"
          onClick={this.toggleDateSelectPanel}
        >
          {showDateSelectPanel ? (
            <i class="date-range-picker__toggle-button__icon mdi mdi-close"></i>
          ) : (
            <i className="date-range-picker__toggle-button__icon mdi mdi-calendar-range mr-2"></i>
          )}

          <span className="date-range-picker__toggle-button__text">
            {visibleDate}
          </span>
        </button>
        {showDateSelectPanel ? (
          <div
            className={
              showDateSelectPanel
                ? "custom-filter-wrap"
                : "custom-filter-wrap hide-calendar"
            }
            // tabindex="0"
            // onBlur={() => this.toggleDateSelectPanel(false)}
          >
            <Form.Group className="row m-0">
              <DateRangePicker
                onChange={this.handleOnChange}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={ranges}
                direction="horizontal"
                inputRanges={[]}
                disabledDates={this.state.maxrange}
                autoclose={true}
                staticRanges={[
                  {
                    label: "Till Date",
                    hasCustomRendering: false,
                    range: () => ({
                      startDate: new Date("2000-01-01"),
                      endDate: new Date(),
                    }),
                    isSelected() {
                      return false;
                    },
                  },
                  {
                    label: "YTD",
                    hasCustomRendering: false,
                    range: () => ({
                      startDate: defineds.startOfYear,
                      //endDate: defineds.endOfYear,
                      endDate:
                        new Date().getTime() <= defineds.endOfYear.getTime()
                          ? new Date()
                          : defineds.endOfYear,
                    }),
                    isSelected() {
                      return false;
                    },
                  },

                  {
                    label: "QTD",
                    hasCustomRendering: false,
                    range: () => ({
                      startDate: defineds.startOfQuarter,
                      //endDate: defineds.endOfQuarter,
                      endDate:
                        new Date().getTime() <= defineds.endOfQuarter.getTime()
                          ? new Date()
                          : defineds.endOfQuarter,
                    }),
                    isSelected() {
                      return false;
                    },
                  },
                  {
                    label: "MTD",
                    hasCustomRendering: false,
                    range: () => ({
                      startDate: defineds.startOfMonth,
                      //  endDate: defineds.endOfMonth,
                      endDate:
                        new Date().getTime() <= defineds.endOfMonth.getTime()
                          ? new Date()
                          : defineds.endOfMonth,
                    }),
                    isSelected() {
                      return false;
                    },
                  },
                  {
                    label: "WTD",
                    hasCustomRendering: false,
                    range: () => ({
                      startDate: defineds.startOfWeek,
                      //  endDate: defineds.endOfWeek,
                      endDate:
                        new Date().getTime() <= defineds.endOfWeek.getTime()
                          ? new Date()
                          : defineds.endOfWeek,
                    }),
                    isSelected() {
                      return false;
                    },
                  },
                ]}
              />
            </Form.Group>
          </div>
        ) : null}
      </div>
    );
  }
}

export default DateFilter;
