import React from "react";
import { Route, Switch } from "react-router-dom";
import { SITE_PATHS } from "config";
import {
  MainLayout,
  AuthorizationLayout,
  BlankLayout,
  InnerLayout,
} from "Layouts";
import { asyncComponent } from "components/Routing";
import { MAIN_ROUTES } from "./routes";
const {
  SIGN_IN_PAGE_PATH,
  SIGN_UP_PAGE_PATH,
  CHANGE_PASSWORD,
  DOCUMENT_VIEWER_PAGE_PATH,
  DISCLAIMER_PAGE_PATH,
  PRIVACY_POLICY_PAGE_PATH,
  LANDING_PAGE,
} = SITE_PATHS;
//main Route
const DisclaimerPage = asyncComponent(() =>
  import("pages/DisclaimerPage").then((module) => module.default)
);

const PrivacyPolicyPage = asyncComponent(() =>
  import("pages/PrivacyPolicyPage").then((module) => module.default)
);
const SiteRoutes = () => (
  <Switch>
    <Route
      exact
      path={[
        SIGN_IN_PAGE_PATH,
        SIGN_UP_PAGE_PATH,
        CHANGE_PASSWORD,
        LANDING_PAGE,
      ]}
      component={AuthorizationLayout}
    />
    <Route
      exact
      path={[
        DOCUMENT_VIEWER_PAGE_PATH,
        `${DOCUMENT_VIEWER_PAGE_PATH}/:documentId`,
      ]}
      component={BlankLayout}
    />
    <Route exact path={DISCLAIMER_PAGE_PATH} component={DisclaimerPage} />
    <Route
      exact
      path={PRIVACY_POLICY_PAGE_PATH}
      component={PrivacyPolicyPage}
    />{" "}
    <Route path={MAIN_ROUTES} component={MainLayout} />
    <Route path="/" component={InnerLayout} />
  </Switch>
);

export default SiteRoutes;
