import React, { useEffect } from "react";
import { useState } from "react";
import { AccountApi } from "services/Api";
import { get as lodashGet } from "lodash";
import { store } from "services/Redux";
import FormData from "form-data";
import toaster from "components/Toaster/Toaster";

function ExcelTab() {
  const [projectList, setProjectList] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const storeData = store.getState();
  const userIdStore = lodashGet(storeData, "user.userData.userInfo.UserId", "");

  const handleSubmitUpload = () => {
    if (!selectedFile) {
      toaster.show({ message: "Please select the file", intent: "danger" });
      return;
    } else if (!selectedProject) {
      toaster.show({ message: "Please select the project", intent: "danger" });
      return;
    } else {
      var formData = new FormData();
      formData.append("ProjectId", selectedProject.Id);
      formData.append("Existing", selectedFile);
      // formData.append("ProjectId", projectDD);
      // // formData.append('SecretKey', ClientSecret);
      // formData.append("Existing", $("input[type=file]")[0].files[0]);
      AccountApi.UploadProfile(formData)
        .then((res) => {
          if (res.status === 200) {
            toaster.show({
              message: "File uploaded successfully",
              intent: "success",
            });
          } else {
            toaster.show({ message: "Upload failed", intent: "danger" });
          }
        })
        .catch((err) => {
          toaster.show({ message: err.message, intent: "danger" });
        });
    }
  };

  const handleProjectChange = (id) => {
    let selected = projectList.filter((item) => item.Id == id)[0];
    setSelectedProject(selected);
  };

  useEffect(() => {
    AccountApi.userProjectsDetails(userIdStore).then((res) => {
      if (res.status === 200) {
        setProjectList(res.data.Body);
      }
    });
  }, []);

  return (
    <>
      <div className="row mx-0 tab-body" id="ExcelTab">
        <div className="col-4">
          <label>Project :</label>
          <select
            onChange={(e) => handleProjectChange(e.target.value)}
            className="form-control mb-4"
          >
            <option selected value="">
              Select Project
            </option>
            {projectList &&
              projectList.map((m) => (
                <option key={m.Id} value={m.Id}>
                  {m.ProjectName}
                </option>
              ))}
          </select>
          <label htmlFor="inputName">Select a File :</label>
          <input
            type="file"
            className="form-control mb-4"
            accept=".json, .xml, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onChange={(e) => setSelectedFile(e.target.files[0])}
          />
          <button
            className="btn btn-primary w-100"
            onClick={handleSubmitUpload}
          >
            Upload File
          </button>
        </div>
      </div>
    </>
  );
}
export default ExcelTab;
