import React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { BiCalendar } from "react-icons/bi";

function DatePicker(props) {
  return (
    <div
      className={`date-picker__container${
        props.className ? " " + props.className : ""
      }${props.fullWidth ? " date-picker__container--full-width" : ""}`}
    >
      <KeyboardDatePicker
        margin="normal"
        className={`date-picker${
          props.inputClassName ? " " + props.inputClassName : ""
        }`}
        disabled={props.isDisabled ? props.isDisabled : false}
        format="MM/DD/YYYY"
        value={props.value}
        onChange={(date) => props.onChange(date)}
        // inputVariant="outlined"
        variant="inline"
        autoOk={true}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        InputAdornmentProps={{
          position: "start",
        }}
        InputProps={{
          disableUnderline: true,
          disabled: props.isDisabled ? props.isDisabled : false,
        }}
        keyboardIcon={<BiCalendar />}
      />
    </div>
  );
}

export default DatePicker;
