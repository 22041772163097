export const SET_USER_DATA = "SET_USER_DATA";
export const SET_SHOW_RESET_PASS_MODAL = "SET_SHOW_RESET_PASS_MODAL";
export const SET_USER_PROFILE_PICTURE = "SET_USER_PROFILE_PICTURE";
export const SET_USER_PROFILE_CONSTANT = "SET_USER_PROFILE_CONSTANT";
export const DESTROY_SESSION = "DESTROY_SESSION";

export function showResetPassModal(data) {
  return { type: SET_SHOW_RESET_PASS_MODAL, data };
}

export function setUserData(data) {
  return { type: SET_USER_DATA, data };
}

export function setUserProfilePicture(data) {
  return { type: SET_USER_PROFILE_PICTURE, data };
}

export function setUserProfileConstant(data) {
  return { type: SET_USER_PROFILE_CONSTANT, data };
}

export function logout() {
  return { type: DESTROY_SESSION };
}

const UserActions = {
  showResetPassModal,
  setUserData,
  setUserProfilePicture,
  setUserProfileConstant,
  logout,
};

export default UserActions;
