const SITE_PATHS = {
  DEVELOPERS_GUIDE_PAGE_PATH: "/dev",
  ADMIN_PANEL_PAGE_PATH: "/AdminPanel/projectId",
  SUPER_ADMIN_PANEL_PAGE_PATH: "/SuperAdminPanel",
  HOME_PAGE_PATH: "/home",
  PLUGINS_PAGE_PATH: "/plugins/projectId",
  PROJECT_PAGE_PATH: "/projectId", //path will be appended by "/PROJECT_ID"
  SIGN_IN_PAGE_PATH: "/login",
  SIGN_UP_PAGE_PATH: "/sign-up",
  ADD_PROJECT_PAGE_PATH: "/AddProject",
  EDIT_PROJECT_PAGE_PATH: "/EditProject/projectId",
  UPLOAD_ALLOCATION_PAGE_PATH: "/upload-allocation",
  DISCLAIMER_PAGE_PATH: "/disclaimer",
  PRIVACY_POLICY_PAGE_PATH: "/privacy-policy",

  PROFILE_EDIT_PAGE_PATH: "/profile/edit",
  PROFILE_PAGE_PATH: "/admin",

  DOCUMENT_VIEWER_PAGE_PATH: "/doc", //path will be appended by "/document_resource_id"
  LOADER_URL: "/verify",
  CHANGE_PASSWORD: "/reset-password",
  LANDING_PAGE: "/",
  DELIVERY_PAGE: "/delivery/projectId",
  // BIOVIEW_PAGE: "/bioview",
  notfound: "/page-not-found",
  NEW_TABLE_PAGE: "/profile-history"
};
//yes, ise tum directly whana bhi call karr sakte ho,
//
export default SITE_PATHS;
