import React from "react";

function SiteHeader() {
  const handlePanel = (e) => {
    var node = document.querySelector(
      ".site-navigation-panel.uncopyable.is-locked"
    );
    var nodeOpen = document.querySelector(".site-navigation-panel.uncopyable");

    if (node) {
      node.setAttribute("class", "site-navigation-panel uncopyable");
    } else if (nodeOpen) {
      nodeOpen.setAttribute(
        "class",
        "site-navigation-panel uncopyable is-locked"
      );
    }
  };

  return (
    <header className="site-header bg-white py-2">
      <div className="d-flex justify-content-between mx-2">
        <div className="">
          <button
            type="button"
            className="btn btn-sm px-3 font-size-16 header-item waves-effect"
            id="vertical-menu-btn"
            onClick={handlePanel}
          >
            <i className="fa fa-fw fa-bars fa-lg" />
          </button>
        </div>

        <div className="text-right pr-3">
          <img
            className="rounded-circle header-profile-user w-10"
            src="https://themesbrand.com/skote/layouts/assets/images/users/avatar-1.jpg"
            alt="Header Avatar"
          />
        </div>
      </div>
    </header>
  );
}

export default SiteHeader;
