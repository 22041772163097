export const SITE_SETTINGS = {
  DEVELOPER_MODE: true,
};

export const API = {
  // BASE_URL: "https://www.profilingstar.com:4431//",
  // BASE_URL: "https://devapi.profilingstar.com/",
  BASE_URL: "https://prodapi.profilingstar.com/",
};

export default API;
