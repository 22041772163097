import { get as lodashGet } from "lodash";
import { AccountApi } from "services/Api";
import { store, userActions } from "services/Redux";
import History from "services/History";
import { SITE_PATHS } from "config";
import Cookies from "js-cookie";

import toaster from "components/Toaster/Toaster";

function getAccessToken() {
  const token1 = Cookies.get("temp_id");

  window.sessionStorage.setItem("token", token1);
  if (!window.sessionStorage.getItem("token")) {
    logout();
  }
  return token1;
}

function login(email, password) {
  return AccountApi.login(email, password)
    .then((res) => {
      // console.log({ res12: res });
      // store.dispatch(userActions.setLoginData(res.data?.LoginDetails));
      if (res.status === 200 && res.data && res.data.LoginDetails !== null) {
        const userData = res.data.LoginDetails;

        // No need to show modal as user is old
        store.dispatch(userActions.showResetPassModal(false));
        store.dispatch(userActions.setUserData(userData));
        const token = lodashGet(userData, "access_token", null);
        const refreshToken = lodashGet(userData, "refresh_token", null);
        sessionStorage.setItem("userData", JSON.stringify(userData));
        var date = new Date();
        date.setTime(date.getTime() + 3600 * 1000);

        Cookies.set("remove_id", refreshToken, {
          expires: date,
          domain: window.location.hostname,
        });
        Cookies.set("temp_id", token, {
          expires: date,
          domain: window.location.hostname,
        });
        Cookies.set("u_id", userData.user_id, {
          expires: date,
          domain: window.location.hostname,
        });
        History.push(SITE_PATHS.HOME_PAGE_PATH);
      } else {
        // Need to show modal as user is New
        if (res.StatusCode == "303" && res.LoginDetails == null) {
          userActions.showResetPassModal(true);
        }
        toaster.show({ message: res.data.Message, intent: "danger" });
      }
    })
    .catch((err) => {
      logout();
    });
}

function logout() {
  Cookies.remove("remove_id", { domain: window.location.hostname });
  Cookies.remove("temp_id", { domain: window.location.hostname });
  Cookies.remove("u_id", { domain: window.location.hostname });

  sessionStorage.removeItem("token");
  //  store.dispatch(userActions.logout(null));
  store.dispatch(userActions.setUserData(null));
  //toaster.show({message: "You have logout successfully", intent: "success"});

  window.location.replace("/login");

  History.push(SITE_PATHS.SIGN_IN_PAGE_PATH);
}

export { login, logout, getAccessToken };
