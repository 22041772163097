import React, { Component } from "react";
import { Link } from "react-router-dom";
import { SITE_PATHS } from "config";

class SiteFooter extends Component {
  render() {
    return (
      <footer className="site-footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 text-center text-lg-left">
              <Link
                className="footer-link"
                to={SITE_PATHS.DISCLAIMER_PAGE_PATH}
                target="_blank"
              >
                Disclaimer
              </Link>
              <Link
                className="footer-link"
                to={SITE_PATHS.PRIVACY_POLICY_PAGE_PATH}
                target="_blank"
              >
                Privacy Policy
              </Link>
            </div>
            <div className="col-lg-6 text-center text-lg-right">
              <small className="copyright-text">
                © Copyright 2022 Sullivan & Bailey
              </small>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default SiteFooter;
