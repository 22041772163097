import React, { Fragment } from "react";
import iconAdminProfile from "assets/images/icons/icon-admin-profile.svg";
import { Loader } from "components/SiteArt";

function ProfileImageUploader(props) {
  return (
    <label
      className={`profile-image-uploader${
        props.className ? " " + props.className : ""
      }`}
    >
      {props.loading ? (
        <div className="profile-image-uploader__loader-container">
          <Loader />
        </div>
      ) : (
        <Fragment>
          <img
            className="profile-image-uploader__image"
            src={
              props.imageSrc
                ? `data:image/png;base64,${props.imageSrc}`
                : iconAdminProfile
            }
            alt={props.imageAlt || "profile"}
          />
          <input
            className="profile-image-uploader__input"
            type="file"
            accept="image/*"
            onChange={(e) => {
              if (props.onChange instanceof Function) {
                props.onChange(e.target.files[0]);
              }
            }}
          />
        </Fragment>
      )}
      <span className="default-image-container__icon fa fa-camera" />
    </label>
  );
}

export default ProfileImageUploader;
