import React from "react";
import { NavLink } from "react-router-dom";
import { SvgIcon } from "components/SiteArt";
import { SITE_PATHS } from "config";
import AuthorizationService from "services/AuthorizationService";

import Logo from "assets/images/logo/Prelogo.png";
import { connect } from "react-redux";
import { FiLogOut } from "react-icons/fi";
import { get as lodashGet } from "lodash";
import { store } from "services/Redux";
import UserActions from "services/Redux/Actions/UserActions";
import iconAdminProfile from "assets/images/icons/icon-admin-profile.svg";
var isBase64 = require("is-base64");
const {
  HOME_PAGE_PATH,
  ADMIN_PANEL_PAGE_PATH,
  UPLOAD_ALLOCATION_PAGE_PATH,
  PROFILE_EDIT_PAGE_PATH,
  PROJECT_PAGE_PATH,
  DELIVERY_PAGE,
  PLUGINS_PAGE_PATH,
  NEW_TABLE_PAGE,
} = SITE_PATHS;

const USER_ROLES = {
  SUPER_ADMIN: "Super Admin",
  PROJECT_ADMIN: "Project Admin",
  SUPER_VISIOR: "Supervisor",
  CHECKER: "Checker",
  MAKER: "Maker",
};

class AppSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSidebarLocked: false,
    };
  }

  toggleIsSidebarLocked = (state) => {
    this.setState({ isSidebarLocked: state });
  };

  handleLogout = (e) => {
    e.preventDefault();
    AuthorizationService.logout();
  };

  render() {
    const { isSidebarLocked } = this.state;
    const { projectImage, projectName, projectId, roleId, roleName } =
      this.props.location.state || {};
    const userRoleInfo = roleName;
    let SIDEBAR_NAV_OPTIONS = [
      {
        title: "Home",
        icon: <SvgIcon className="nav-link__icon" icon="iconHome" />,
        linkTo: HOME_PAGE_PATH,
        roles: Object.values(USER_ROLES),
      },
      {
        title: "Listing Upload",
        icon: (
          <SvgIcon className="nav-link__icon" icon="iconuploadAllocation" />
        ),
        linkTo: UPLOAD_ALLOCATION_PAGE_PATH,
        roles: [
          USER_ROLES.SUPER_ADMIN,
          USER_ROLES.PROJECT_ADMIN,
          USER_ROLES.SUPER_VISIOR,
        ],
      },
      {
        title: "Work Space",
        icon: <SvgIcon className="nav-link__icon" icon="iconStaging" />,
        linkTo: `${PROJECT_PAGE_PATH}/${projectId}`,
        roles: Object.values(USER_ROLES),
      },
      {
        title: "Delivery",
        icon: <SvgIcon className="nav-link__icon" icon="iconDeliver" />,
        linkTo: `${DELIVERY_PAGE}/${projectId}`,
        roles: [
          USER_ROLES.SUPER_ADMIN,
          USER_ROLES.PROJECT_ADMIN,
          USER_ROLES.SUPER_VISIOR,
        ],
      },
      {
        title: "Profile History",
        icon: <SvgIcon className="nav-link__icon" icon="resources" />,
        linkTo: `${NEW_TABLE_PAGE}/${projectId}`,
        roles: Object.values(USER_ROLES),
      },
      {
        title: "Settings",
        icon: <SvgIcon className="nav-link__icon" icon="iconPlugins" />,
        linkTo: `${PLUGINS_PAGE_PATH}/${projectId}`,
        roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.PROJECT_ADMIN],
      },
      {
        title: "Admin Panel",
        icon: <SvgIcon className="nav-link__icon" icon="iconAdminPanel" />,
        linkTo: ADMIN_PANEL_PAGE_PATH,
        roles: [USER_ROLES.PROJECT_ADMIN, USER_ROLES.SUPER_VISIOR],
      },
      // {
      //   title: "QAS",
      //   icon: <SvgIcon className="nav-link__icon" icon="iconDeliver" />,
      //   linkTo:  `${SITE_PATHS.QUICK_SCAN}/${projectId}`,
      //   roles: Object.values(USER_ROLES),
      // },
    ];

    const { userProfilePicture, userProfileConstant } = this.props;
    const storeData = store.getState();

    const userStoreRoleInfo = lodashGet(
      storeData,
      "user.userData.userInfo.ProjectRoleMappings",
      ""
    );
    const rolename =
      userStoreRoleInfo.length > 0
        ? userStoreRoleInfo.filter((x) => x.ProjectId == projectId).length > 0
          ? userStoreRoleInfo.filter((x) => x.ProjectId == projectId)[0]
              .RoleName
          : ""
        : "";

    return (
      <div
        className={`site-navigation-panel uncopyable${
          isSidebarLocked ? " is-locked" : ""
        }`}
      >
        <div className="brand-logo-container">
          <img
            className="brand-logo-img"
            src={projectImage ? `data:image/png;base64,${projectImage}` : Logo}
            alt="Profiling Star"
          />
          {projectName ? (
            // <div className="text-center sidebar-brand-wrapper d-flex align-items-center">

            // </div>
            <span className="nav-link__title  nav-link__title--brand-name logo-white-clor innerProjectName">
              {projectName}
            </span>
          ) : (
            <>
              <span className="nav-link__title  nav-link__title--brand-name logo-white-clor">
                Profiling
                <br /> Star
              </span>
            </>
          )}
        </div>
        <div className="site-navigation-panel-lock__container">
          <input
            type="checkbox"
            id="site-navigation-panel-lock__toggle-input"
            className="site-navigation-panel-lock__toggle-input"
            onClick={(e) => this.toggleIsSidebarLocked(e.target.checked)}
          />
          <label
            className="site-navigation-panel-lock__toggle-input-label"
            htmlFor="site-navigation-panel-lock__toggle-input"
          >
            <span
              className={`site-navigation-panel-lock__icon-unlock mdi mdi-lock-open-variant${
                isSidebarLocked ? " hidden" : ""
              }`}
            />
            <span
              className={`site-navigation-panel-lock__icon-lock mdi mdi-lock${
                !isSidebarLocked ? " hidden" : ""
              }`}
            />
          </label>
        </div>
        <nav className="site-navigation-panel__nav">
          <ul>
            {rolename
              ? SIDEBAR_NAV_OPTIONS.filter((nav) =>
                  nav.roles.includes(rolename)
                ).map((navItem, i) => {
                  return (
                    <li key={i} data-toggle="tooltip" title={navItem.title}>
                      <NavLink
                        className="nav-link"
                        to={{
                          pathname: navItem.linkTo,
                          state: {
                            projectName: projectName,
                            projectImage: projectImage,
                            projectId: projectId,
                            roleId: roleId,
                            roleName: roleName,
                          },
                        }}
                      >
                        <span className="nav-link__icon-container">
                          {navItem.icon}
                        </span>
                        <span className="nav-link__title">{navItem.title}</span>
                      </NavLink>
                    </li>
                  );
                })
              : SIDEBAR_NAV_OPTIONS.filter((nav) => nav.title == "Home").map(
                  (navItem, i) => {
                    return (
                      <li key={i} data-toggle="tooltip" title={navItem.title}>
                        <NavLink
                          className="nav-link"
                          to={{
                            pathname: navItem.linkTo,
                            state: {
                              projectName: projectName,
                              projectImage: projectImage,
                              projectId: projectId,
                              roleId: roleId,
                              roleName: roleName,
                            },
                          }}
                        >
                          <span className="nav-link__icon-container">
                            {navItem.icon}
                          </span>
                          <span className="nav-link__title">
                            {navItem.title}
                          </span>
                        </NavLink>
                      </li>
                    );
                  }
                )}

            <li className="mt-auto" data-toggle="tooltip" title="Profile">
              <NavLink
                className="nav-link nav-link--profile"
                to={PROFILE_EDIT_PAGE_PATH}
              >
                <span className="nav-link__icon-container nav-link__icon-container--profile">
                  <img
                    className="nav-link__icon p-0"
                    src={
                      userProfilePicture && isBase64(userProfilePicture)
                        ? `data:image/png;base64,${userProfilePicture}`
                        : iconAdminProfile
                    }
                    alt="user-profile"
                  />
                </span>
                <span className="nav-link__title">Profile</span>
              </NavLink>
            </li>

            <li data-toggle="tooltip" title="Logout">
              <NavLink
                className="nav-link nav-link__logout"
                to="/login"
                title="Logout"
                onClick={this.handleLogout}
              >
                <span className="nav-link__icon-container">
                  {/* <FiLogOut color="white" size="20" /> */}
                  <SvgIcon className="nav-link__icon" icon="iconLogout" />
                </span>
                <span className="nav-link__title">Logout</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfilePicture: state.user.userData?.userInfo?.UserImage,
  userProfileConstant: state.user.userProfileConstant,
});

export default connect(mapStateToProps)(AppSidebar);
