import { SITE_PATHS } from "config";

export const MAIN_ROUTES = [
  SITE_PATHS.HOME_PAGE_PATH,
  // SITE_PATHS.ADMIN_PANEL_PAGE_PATH,
  SITE_PATHS.SUPER_ADMIN_PANEL_PAGE_PATH,
  SITE_PATHS.DEVELOPERS_GUIDE_PAGE_PATH,
  SITE_PATHS.ADD_PROJECT_PAGE_PATH,
  SITE_PATHS.EDIT_PROJECT_PAGE_PATH,
  SITE_PATHS.PROFILE_EDIT_PAGE_PATH,
  SITE_PATHS.PROFILE_PAGE_PATH,
  SITE_PATHS.LOADER_URL,
  SITE_PATHS.SIGN_IN_PAGE_PATH,
];
