import React from "react";

export function ResetButton(props) {
  return (
    <button {...props} className="reset-button">
      {/* <span className="reset-button__icon"></span> */}
      <span className="fa fa-refresh mr-2"></span>
      <span>Reset</span>
    </button>
  );
}

export function AddRoundButton(props) {
  return (
    <button {...props} className="add-round-button">
      <span className="fa fa-plus"></span>
    </button>
  );
}

export function CloseRoundButton(props) {
  return (
    <button {...props} className="close-round-button">
      <span className="fa fa-times close-round-button__icon"></span>
    </button>
  );
}

export function ResetButtonSquare(props) {
  return (
    <button {...props} className="reset-button-square">
      <span className="fa fa-refresh"></span>
    </button>
  );
}
