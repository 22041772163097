import React, { useEffect } from "react";
import { useState } from "react";
import { AccountApi } from "services/Api";
import toaster from "components/Toaster/Toaster";
import deleteicon from "assets/images/icons/deleteicon.png";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Icon from "@mui/material/Icon";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { PopupOverlay } from "components/Popup";

import {
  Dropdown,
  DropdownButton,
  FormControl,
  InputGroup,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import Switch from "react-switch";
import Button from "reactstrap/lib/Button";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import { CloseRoundButton } from "components/Input";
import { isObject } from "lodash";
import { Loader } from "components/SiteArt";

function AuditAPIIntegrationTab(props) {
  const [isEnabled, setisEnabled] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [tabKey, setTabKey] = useState("headers");
  const [paramRows, setParamRows] = useState([0]);
  const [headerRows, setheaderRows] = useState([0]);
  const [testModalShow, setTestModalShow] = useState(false);
  const [testApiData, setTestApiData] = useState({});
  const [postUrl, setPostUrl] = useState("");
  const [payloadData, setPayloadData] = useState({});
  const [json, setJson] = useState({});
  const { projId } = props;
  let headersResponseObj = {};

  useEffect(() => {
     getIntegrationApi(projId)
    // getEnabledSettingApi(projId);
  }, []);

  const toggleTestModal = () => {
    const obj = {};
    document.querySelectorAll("#ListingTable tbody tr").forEach((val) => {
      obj[val.getElementsByTagName("input")[0].value] =
        val.getElementsByTagName("input")[1].value;
    });
    // Leave this
    // setPayloadData({
    //   ...payloadData,
    //   ...{ Headers: obj },
    //   ...{ Body: json },
    // });

    const payload = {
      // Enabled: isEnabled,
      // ProjectId: projId,
      // Post: true,
      // Put: false,
      // Get: false,
      Body: json,
      Headers: obj,
      ApiEndPoint: postUrl,
    };

    const filteredObj =
      Object.keys(obj).filter((key) => key != "").length > 0 ? obj : {};

    fetch(postUrl, {
      method: "post",
      headers: { "Content-Type": "application/json", ...filteredObj },
      body: JSON.stringify(json),
    })
      .then((response) => response.json())
      .then((response) => setPayloadData(response));

    setTestModalShow(!testModalShow);
  };

  const postUrlChangeHandler = (e) => {
    setPostUrl(e.target.value);
  };

  const getIntegrationApi = (projId) => {
    AccountApi.getListingUploadApi(projId).then((res) => {
      if (res.status === 200) {
        if (res.data.ResponseCode === 500) {
          toaster.show({ message: "Service unavailable", intent: "danger" });
        } else {
          if( res.data.Body!==null){
          setIsLoading(false);
          setisEnabled(res.data.Body.IsActive);
          setJson(JSON.parse(res.data.Body.Body));
          headersResponseObj = JSON.parse(res.data.Body.Headers);
          setheaderRows([
            ...Array(Object.keys(headersResponseObj).length).keys(),
          ]);
          res.data.Body.Body = JSON.parse(res.data.Body.Body);
          res.data.Body.Headers = JSON.parse(res.data.Body.Headers);
          setPayloadData(res.data.Body);
          setPostUrl(res.data.Body.EndPoint);
        }else{
          setIsLoading(false);
        }
        }
      }
    });
  };

  // const getEnabledSettingApi = (projId) => {
  //   AccountApi.getApiEnabledSetting(projId, isEnabled, true).then((res) => {
  //     if (res.status === 200) {
  //       setisEnabled(res.data.Body.Enabled);
  //     }
  //   });
  // };

  useEffect(() => {
    return () => {
      Object.keys(headersResponseObj).forEach(function (key, index) {
        document
          .querySelectorAll("#ListingTable tbody tr")
          [index].getElementsByTagName("input")[0].value = key;
        document
          .querySelectorAll("#ListingTable tbody tr")
          [index].getElementsByTagName("input")[1].value =
          headersResponseObj[key];
      });
    };
  }, [headerRows]);

  const toggleSwtich = () => {
    setisEnabled(!isEnabled);
    submitHandler(!isEnabled);
  };

  // const testPayloadApiResponse = (body) => {
  //   // const payload = {
  //   //   Enabled: enabled,
  //   //   ProjectId: projId,
  //   //   Post: true,
  //   //   Put: false,
  //   //   Get: false,
  //   //   Body: JSON.stringify(json),
  //   //   Headers: JSON.stringify(obj),
  //   //   ApiEndPoint: postUrl,
  //   // };
  //   setPayloadData(body);
  // };

  const submitHandler = (enabled) => {
    const obj = {};
    document.querySelectorAll("#ListingTable tbody tr").forEach((val) => {
      obj[val.getElementsByTagName("input")[0].value] =
        val.getElementsByTagName("input")[1].value;
    });

    const payload = {
      EndPoint: postUrl,
      ProjectId: projId,
      Headers: JSON.stringify(obj),
      Body: JSON.stringify(json),
      isActive: enabled,
    };
    AccountApi.postLISTINGAPI(payload)
      .then((res) => {
        if (res.status === 200) {
          toaster.show({ message: res.data.Message, intent: "success" });
          // console.log("success");
        }
      })
      .catch((e) => {
        toaster.show({ message: "Something went wrong", intent: "danger" });
        // console.log("something went wrong");
      });
  };

  const addHeadersRowHandler = () => {
    setheaderRows([...headerRows, headerRows[headerRows.length - 1] + 1]);
  };

  const deleteParamRowHandler = (id) => {
    const updatedRows = paramRows.filter((x) => x != id);
    setParamRows(updatedRows);
  };

  const deleteHeadersRowHandler = (id) => {
    const updatedRows = headerRows.filter((x) => x != id);
    setheaderRows(updatedRows);
  };

  const handleSchemaChangeValue = (event) => {
    try {
      const parseVal = JSON.parse(event.plainText);
      setJson(parseVal);
      return;
    } catch (e) {}
    if (event.error) return;
    setJson(event.jsObject);
  };

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="row mx-0" id="RoleListTab">
            <div className="d-flex align-items-center mb-3" id="example">
              Enable/Disable
              <Switch
                onChange={(e) => toggleSwtich()}
                checked={isEnabled}
                className="react-switch1"
                offHandleColor="#808080"
                onHandleColor="#0000FF"
              />
            </div>
          </div>
          <div className="row mx-0">
            <InputGroup className="mb-5">
              <DropdownButton
                variant="outline-primary"
                title="POST"
                id="input-group-dropdown-1"
                disabled={!isEnabled}
              >
                <Dropdown.Item href="#">POST</Dropdown.Item>
                {/* <Dropdown.Item href="#">GET</Dropdown.Item>
              <Dropdown.Item href="#">PUT</Dropdown.Item> */}
              </DropdownButton>
              <FormControl
                value={postUrl}
                onChange={(e) => postUrlChangeHandler(e)}
                className="col-6"
                disabled={!isEnabled}
                aria-label="Text input with dropdown button"
              />
              <Button
                className="btn btn-primary ml-3"
                disabled={!isEnabled}
                onClick={() => submitHandler(true)}
              >
                Submit
              </Button>
              {testModalShow && (
                <PopupOverlay
                  className="modal-details-popup-testapi"
                  modal={true}
                  toggle={toggleTestModal}
                  body={
                    <div className="d-flex flex-column  mt-1">
                      <div>
                        <h4>API Response: </h4>
                        <div className="ml-3 mt-1">
                          <JSONInput
                            placeholder={payloadData}
                            locale={locale}
                            height="500px"
                            viewOnly={true}
                            theme="light_mitsuketa_tribute"
                          />
                        </div>
                      </div>
                      {/* <div className="d-flex">
                      <h4>Body: </h4>
                      <div className="ml-3 mt-1">{JSON.stringify(json)}</div>
                    </div> */}
                      {/* <h3>Params : </h3>
                    {JSON.stringify(testApiData)} */}
                    </div>
                  }
                  title={"Preview Post Api Request"}
                />
              )}
              <Button
                className="btn btn-primary ml-3"
                disabled={postUrl ? false : true}
                onClick={() => {
                  toggleTestModal();
                }}
              >
                Test Data
              </Button>
            </InputGroup>
          </div>
          <div>
            <Tabs
              id="controlled-tab-example"
              activeKey={tabKey}
              onSelect={(k) => setTabKey(k)}
              className="mb-4"
            >
              <Tab eventKey="headers" title="Headers" disabled={!isEnabled}>
                <Table
                  striped
                  bordered
                  hover
                  className="tabTable"
                  id="ListingTable"
                >
                  <thead>
                    <tr>
                      <th>Key</th>
                      <th>Value</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {headerRows.map((value) => {
                      return (
                        <tr key={value} id={value}>
                          <td>
                            <FormControl
                              // className="col-12"
                              aria-label="Text input with dropdown button"
                              disabled={!isEnabled}
                            />
                          </td>
                          <td>
                            <FormControl
                              // className="col-12"
                              aria-label="Text input with dropdown button"
                              disabled={!isEnabled}
                            />
                          </td>
                          <td>
                            {isEnabled ? (
                              <AddCircleOutlineIcon
                                onClick={(e) => addHeadersRowHandler()}
                                style={{
                                  cursor: "pointer",
                                }}
                              />
                            ) : (
                              <AddCircleOutlineIcon
                                style={{ color: "lightgray" }}
                                disabled
                              />
                            )}
                            {isEnabled && headerRows.length > 1 ? (
                              <DeleteForeverIcon
                                onClick={() => deleteHeadersRowHandler(value)}
                                style={{ width: 50, cursor: "pointer" }}
                              />
                            ) : (
                              <DeleteForeverIcon
                                style={{ color: "lightgray" }}
                                disabled
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Tab>
              <Tab eventKey="body" title="Body" disabled={!isEnabled}>
                <JSONInput
                  id="a_unique_id"
                  placeholder={json}
                  locale={locale}
                  height="500px"
                  reset={true}
                  onChange={handleSchemaChangeValue}
                  theme="light_mitsuketa_tribute"
                />
              </Tab>
              {/* <Tab eventKey="params" title="Params" disabled={!isEnabled}>
                         </Tab> */}
            </Tabs>
          </div>
        </>
      )}
    </>
  );
}
export default AuditAPIIntegrationTab;
