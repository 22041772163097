import React from "react";
import { MainLayoutRoutes } from "routes";
import { SiteNavigationPanel, SiteFooter } from "components/Layout";

const MainLayout = () => (
  <div className="main-layout">
    <SiteNavigationPanel />
    <main className="main-layout__main">
      <MainLayoutRoutes />
    </main>
    <SiteFooter />
  </div>
);

export default MainLayout;
