import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { SITE_PATHS } from "config";
import { asyncComponent } from "components/Routing";
import { get as lodashGet } from "lodash";
import { store } from "services/Redux";
import Plugins from "pages/PluginsPage";
import ProtectedRoute from "./ProtectedRoute";
const {
  HOME_PAGE_PATH,
  PROJECT_PAGE_PATH,
  ADMIN_PANEL_PAGE_PATH,
  DEVELOPERS_GUIDE_PAGE_PATH,
  ADD_PROJECT_PAGE_PATH,
  EDIT_PROJECT_PAGE_PATH,
  PROFILE_EDIT_PAGE_PATH,
  UPLOAD_ALLOCATION_PAGE_PATH,
  PROFILE_PAGE_PATH,
  LOADER_URL,
  DELIVERY_PAGE,
  SIGN_IN_PAGE_PATH,
  // ADMIN_PANEL_PAGE_PATH,
  PLUGINS_PAGE_PATH,
  NEW_TABLE_PAGE
} = SITE_PATHS;

const DevelopersGuidePage = asyncComponent(() =>
  import("pages/DevelopersGuide").then((module) => module.default)
);

const Home = asyncComponent(() =>
  import("pages/Home").then((module) => module.default)
);
const Project = asyncComponent(() =>
  import("pages/Project").then((module) => module.default)
);

const NewTable = asyncComponent(() =>
  import("pages/Profile History").then((module) => module.default)
);

const AddProjectPage = asyncComponent(() =>
  import("pages/AddProjectPage").then((module) => module.default)
);
const EditProjectPage = asyncComponent(() =>
  import("pages/EditProjectPage").then((module) => module.default)
);

const ProfileEditPage = asyncComponent(() =>
  import("pages/ProfileEditPage").then((module) => module.default)
);

const PluginPage = asyncComponent(() =>
  import("pages/PluginsPage").then((module) => module.default)
);

const AdminPanel = asyncComponent(() =>
  import("pages/AdminPanel").then((module) => module.default)
);

const ProfilePage = asyncComponent(() =>
  import("pages/ProfilePage").then((module) => module.default)
);
const UploadAllocationPage = asyncComponent(() =>
  import("pages/UploadAllocationPage").then((module) => module.default)
);

const VerifyPage = asyncComponent(() =>
  import("pages/VerifyPage").then((module) => module.default)
);

const DeliveryPage = asyncComponent(() =>
  import("pages/Delivery").then((module) => module.default)
);

function MainLayoutRoutes() {
  const storeData = store.getState();
  const userStoreRoleInfo = lodashGet(
    storeData,
    "user.userData.userInfo.MainRole.RoleName",
    ""
  );
  return (
    <Switch>
      <ProtectedRoute
        exact
        path={DEVELOPERS_GUIDE_PAGE_PATH}
        component={DevelopersGuidePage}
      />
      <ProtectedRoute exact path={HOME_PAGE_PATH} component={Home} />
      <ProtectedRoute
        exact
        path={ADMIN_PANEL_PAGE_PATH}
        component={AdminPanel}
      />
      <ProtectedRoute
        exact
        path={`${PROJECT_PAGE_PATH}/:projectID`}
        component={Project}
      />
      <ProtectedRoute
        exact
        path={`${NEW_TABLE_PAGE}/:projectID`}
        component={NewTable}
      />
      <ProtectedRoute
        exact
        path={ADD_PROJECT_PAGE_PATH}
        component={AddProjectPage}
      />
      <ProtectedRoute
        exact
        path={`${EDIT_PROJECT_PAGE_PATH}/:projectID`}
        component={EditProjectPage}
      />
      <ProtectedRoute
        exact
        path={`${PROFILE_EDIT_PAGE_PATH}`}
        component={ProfileEditPage}
      />
      <ProtectedRoute
        exact
        path={`${PROFILE_PAGE_PATH}`}
        component={ProfilePage}
      />
      <ProtectedRoute
        exact
        path={`${UPLOAD_ALLOCATION_PAGE_PATH}`}
        component={UploadAllocationPage}
      />
      <ProtectedRoute
        exact
        path={`${DELIVERY_PAGE}/:projectID`}
        component={DeliveryPage}
      />

      <ProtectedRoute
        exact
        path={`${PLUGINS_PAGE_PATH}/:projectID`}
        component={PluginPage}
      />

      <ProtectedRoute
        exact
        path="/"
        render={() => <Redirect to={SIGN_IN_PAGE_PATH} />}
      />
      <ProtectedRoute exact path={`${LOADER_URL}`} component={VerifyPage} />
    </Switch>
  );
}

export default MainLayoutRoutes;
