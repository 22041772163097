import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

export function PopupOverlay(props) {
  const { modal, toggle, body, title, className } = props;
  return (
    <Modal isOpen={modal} toggle={toggle} className={className}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>{body}</ModalBody>
    </Modal>
  );
}
