import React from "react";
import { Route, Switch } from "react-router-dom";
import { SITE_PATHS } from "config";
import { asyncComponent } from "components/Routing";

const { SIGN_IN_PAGE_PATH, SIGN_UP_PAGE_PATH, CHANGE_PASSWORD,LANDING_PAGE } = SITE_PATHS;

const SignInPage = asyncComponent(() =>
  import("pages/SignInPage").then(module => module.default)
);

const SignUpPage = asyncComponent(() =>
  import("pages/SignUpPage").then(module => module.default)
);

const ChangePasswordPage = asyncComponent(() =>
  import("pages/ChangePasswordPage").then(module => module.default)
);

const LandingPage = asyncComponent(() =>
  import("pages/Landing").then(module => module.default)
);

const AuthorizationLayout = () => (
  <Switch>
    <Route path={SIGN_IN_PAGE_PATH} component={SignInPage} />
    <Route path={LANDING_PAGE} component={LandingPage} />
    <Route path={SIGN_UP_PAGE_PATH} component={SignUpPage} />
    <Route path={CHANGE_PASSWORD} component={ChangePasswordPage} />
  </Switch>
);

export default AuthorizationLayout;
