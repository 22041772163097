// ------------------| ACCOUNTS ENDPOINTS |------------------

//PROFILING STAR  APIs
export const SSO_LOGIN_ENDPOINT = "api/SignIn";
export const USER_DATA_ENDPOINT = "api/UserDetails?UserId=";
export const UPDATE_USER_DATA_ENDPOINT = "api/UserDetails/user";
export const USER_PROJECTS_ENDPOINT = "api/ProjectDetails?userId=";
export const USER_ALLOCATION_ENDPOINT = "api/Allocation";
export const USER_MASTERFILTER_ENDPOINT = "api/MasterFilter";
//PROFILING STAR ADMIN APIs
export const USERS_LIST_ENDPOINT = "api/Users";
export const ROLE_OPTIONS_ENDPOINT = "api/Roles";
export const ADMIN_USERS_ROLE_MAPPING_ENDPOINT = "api/UsersRolesMapping";
export const ADMIN_ADD_USER_ENDPOINT = "api/Signup";
export const ADMIN_DECIDE_USER_ROLE_ENDPOINT = "api/Users/user";
export const ADMIN_PROJECT_LIST_ENDPOINT = "api/ProjectDetails/Listing";
export const ADMIN_PROJECT_SCHEMA_ENDPOINT = "api/JsonSchema";

//Plugins API
export const ADD_PLUGIN = "api/Addplugin";
//AddProfile API
export const UPLOAD_PROFILE = "api/UploadProfile";

//ADD PROJECT PAGE APIs
export const ADD_PROJECT_ENDPOINT = "api/Project";
//EDIT PROJECT PAGE APIs
export const FETCH_PROJECT_DETAILS_ENDPOINT = "api/DataByProjectId";
export const UPDATE_PROJECT_DETAILS_ENDPOINT = "api/UpdateProject";
//UPLOAD ALLOCATION PAGE APIs
export const UPLOAD_ALLOCATION_ENDPOINT = "api/Listing";
export const FETCH_LISTING_HISTORY_ENDPOINT = "api/ListingHistoryByProjectId";

export const SSO_CHANGE_PASSWORD_ENDPOINT = "v2/user/change_password";

export const REGISTER_ENDPOINT = "v1/user/register";

export const USER_PROFILE_IMAGE_ENDPOINT = "v1/user/image";
export const UPDATE_USER_PROFILE_IMAGE_ENDPOINT = "v1/user/image";

export const FORGOT_PASSWORD_ENDPOINT =
  "api/RequestForConfirmationCodeToSetPassword";
export const RESET_FORGOT_PASSWORD_ENDPOINT = "api/RequestForSetPassword";

// ------------------| REFRESH & CONFIRM RMAIL ENDPOINTS |------------------

export const REFRESH_TOKEN = "v1/refresh";
export const CONFIRM_EMAIL = "v1/user/confirm_email";
//download file
export const DOWNLOAD_FILE = "api/DownloadById";
//Fiter calender Api
export const CALENDER_FILTER = "api/Allocation/filter";

// allocation filter api
export const ALLOCATION_FILTER = "api/AllocationFilter";

// delivery filter api
export const DELIVERY_FILTER = "api/DeliveryFilter";

//select status api

export const STATUS_LIST = "api/ChangeStatus";

// Get Usernames list api
export const USER_NAMES_LIST = "api/GetUserName";

//Allocate User Super Admin User
export const ALLOCATE_USER = "api/Allocation/user";

//reopen case
export const REOPEN_CASE = "api/ReopenCase";

//Fetch delivery data
export const FETCH_DELIVERY_DATA = "api/SubmitProfiles";

//Final Submit Listing Ids
export const FINAL_SUBMIT = "api/SubmitProfilesById";

//delete enitites

export const DELETE_BY_STATUS = "api/DeleteBystatusAndListingIds";

//Get json schema api
export const GET_JSON_SCHEMA = "api/GetJsonSchema";

// get api integration tab call
export const GET_API_INTEGRATION = "api/GetApiIntegrationSetting";
export const GET_LISTING_API="api/GetListingUploadApi";
export const GET_REMEDIATION_DATA_API="api/GetRemediationDataApi";
export const GET_REMEDIATION_HEADER_API="api/GetRemediationHeaderApi";
export const GET_MIGRATION_API ="api/GetStagingToProdApiSetting"
//get enable settings for integration
export const GET_API_SETTINGS = "api/GetApiEnabledSetting";

//get all api 
export const GET_ALL_API = "api/GetAllApi";
// post api integration call
export const INSERT_LISTING_UPLOAD_API="api/InsertListingUploadApi";
export const POST_API_INTEGRATION = "api/ApiDeliverySetting";
export const INSERT_REMEDIATION_API="api/InsertRemediationDataSetting";
export const INSERT_REMEDIATION_HEADER_API="api/InsertRemediationHeaderSetting ";
export const INSERT_MIGRATIONS_API="api/InsertStagingToProdApiSetting  ";
export const DOWNLOAD_SETTINGS = "api/GetDownloadSetting";

export const UPDATE_DOWNLOAD_SETTINGS = "api/DownloadSetting";

export const SKIP_CHECKER_MAKER = "api/SkipMakerChecker";

export const ONHOLD_STATUS = "api/ProfileOnHold";

export const DELIVERY_DATA_API = "api/DeliverDataByApi";

export const HEADER_SETTING_API = "api/GetHeaderSetting";

export const POST_HEADER_SETTING_API = "api/PostHeaderSetting";

export const SAMPLE_FILE_DOWNLOAD_API = "api/SampleFormat";

export const UPDATE_PASSWORD_API = "api/UpdatePassword";
