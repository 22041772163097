import React from "react";
import TabContainer from "components/TabContainer";
import AssociationMapTab from "./AssociationMap";
import ExcelTab from "./Excel";
import ApiIntegrationTab from "./ApiIntegration";
import APIIntegrationsheader from "./APIIntegrationsheader";
import Downloadsetup from "./Downloadsetup";
import TableHeadersTab from "./TableHeaders";

import { get as lodashGet } from "lodash";
import { store } from "services/Redux";

function Plugins(props) {
  const storeData = store.getState();
  const userStoreRoleInfo = lodashGet(
    storeData,
    "user.userData.userInfo.MainRole.RoleName",
    ""
  );
  return (
    <>
      <div className="container-fluid py-3 px-3">
        <h1 className="site-page__heading py-3">Admin Settings</h1>
        <TabContainer>
          {/* ============Users ============*/}
          {/* <div name="Association Maps">
            <AssociationMapTab />
          </div> */}
          {/* ============Roles ============*/}
          {/* <div name="Data Migration">
            <ExcelTab />
          </div> */}
          {/* ============Project Schema ============*/}
          <div name="Table Headers">
            <div>
              <TableHeadersTab projId={props.location.state.projectId} />
            </div>
          </div>
          <div name="Download Setup">
            <div>
              <Downloadsetup />
            </div>
          </div>
          <div name="APIs Integration">
            <div>
              <APIIntegrationsheader projId={props.location.state.projectId} />
            </div>
          </div>
        </TabContainer>
      </div>
    </>
  );
}

export default Plugins;
