import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../Reducers";
import thunk from "redux-thunk";
import { createPromise } from "redux-promise-middleware";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ['user']
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const createStoreWithMiddleware = compose(
  applyMiddleware(
    createPromise({ promiseTypeSuffixes: ["LOADING", "SUCCESS", "ERROR"] }),
    thunk
    // createLogger()
  ),
  window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : (f) => f
)(createStore);

const store = createStoreWithMiddleware(persistedReducer);
const persistor = persistStore(store);

export default store;
export { persistor };
