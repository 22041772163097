import React from "react";
import { Route, Switch } from "react-router-dom";
import { SITE_PATHS } from "config";
import { asyncComponent } from "components/Routing";

const { DOCUMENT_VIEWER_PAGE_PATH } = SITE_PATHS;

const DocumentViewerPage = asyncComponent(() =>
  import("pages/DocumentViewerPage").then((module) => module.default)
);

const BlankLayoutRoutes = () => (
  <Switch>
    <Route
      exact
      path={`${DOCUMENT_VIEWER_PAGE_PATH}/:documentId`}
      component={DocumentViewerPage}
    />

    <Route
      exact
      path={`${DOCUMENT_VIEWER_PAGE_PATH}`}
      component={DocumentViewerPage}
    />
  </Switch>
);

export default BlankLayoutRoutes;
