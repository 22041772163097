import React ,{useState} from 'react'
import {
  Tab,
  Tabs,
} from "react-bootstrap";
import ApiIntegrationTab from "./ApiIntegration";
import AuditAPIIntegrationTab from './AuditAPIIntegration';
import MigrationsTab from './MigrationsTab';
import RemediationDataTab from './RemediationDataTab';
import RemediationHeaderTab from './RemediationHeaderTab';

const APIIntegrationsheader = (props) => {
    const { projId } = props;
    const [tabKey, setTabKey] = useState("listing");
  return (
    <div>
        <Tabs
         id="controlled-tab-example"
         activeKey={tabKey}
         onSelect={(k) => setTabKey(k)}
         className="mb-4">
        <Tab eventKey="listing" title="Delivery" >
            <div><ApiIntegrationTab projId={projId} name={"Listing"} /></div>
        </Tab>
        <Tab eventKey="Audit" title="Listing Upload">
            <div><AuditAPIIntegrationTab projId={projId} name={"Audit"} /></div>
        </Tab>
        <Tab eventKey="RemediationData" title="Remediation Data">
            <div><RemediationDataTab projId={projId} name={"RemediationData"} /></div>
        </Tab>
        <Tab eventKey="RemediationHeader" title="Remediation Header">
            <div><RemediationHeaderTab projId={projId} name={"RemediationHeader"} /></div>
        </Tab>
        <Tab eventKey="migration" title="Migration">
            <div><MigrationsTab projId={projId} name={"migration"} /></div>
        </Tab>
        </Tabs>
    </div>
  )
}

export default APIIntegrationsheader