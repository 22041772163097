import React from "react";
import { BlankLayoutRoutes } from "routes";

const BlankLayout = () => (
  <div className="">
    <main className="main-layout__main">
      <BlankLayoutRoutes />
    </main>
  </div>
);

export default BlankLayout;
