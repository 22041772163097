import iconHome from "assets/images/icons/icon-home.svg";
import iconApartment from "assets/images/icons/icon-apartment.svg";
import iconBriefcase from "assets/images/icons/icon-briefcase.svg";
import iconDashboard from "assets/images/icons/icon-dashboard.svg";
import iconFile from "assets/images/icons/icon-file.svg";
import iconGavel from "assets/images/icons/icon-gavel.svg";
import iconInfo from "assets/images/icons/icon-info.svg";
import iconNotifications from "assets/images/icons/icon-notifications.svg";
import iconSearch from "assets/images/icons/icon-search.svg";
import iconServer from "assets/images/icons/icon-server.svg";
import iconStarBox from "assets/images/icons/icon-star-box.svg";
import iconTriangle from "assets/images/icons/icon-triangle.svg";
import iconUserSettings from "assets/images/icons/icon-user-settings.svg";
import iconUser from "assets/images/icons/icon-user.svg";
import iconWorld from "assets/images/icons/icon-world.svg";
import iconPrison from "assets/images/icons/icon-prison.svg";
import iconAdminProfile from "assets/images/icons/icon-admin-profile.svg";
import iconEventsRound from "assets/images/icons/events-white-round.png";
import iconUpload from "assets/images/icons/cloud-computing.svg";
import iconStaging from "assets/images/icons/staging.svg";
import iconEdit from "assets/images/icons/edit.svg";
import iconDownload from "assets/images/icons/download.svg";
import iconAdminPanel from "assets/images/icons/adminPanel.svg";
import iconAddProject from "assets/images/icons/addProject.svg";
import iconPlugins from "assets/images/icons/plugin-5.png";
import iconLogout from "assets/images/icons/logout.svg";
import iconuploadAllocation from "assets/images/icons/uploadAllocation.svg";
import iconDeliver from "assets/images/icons/Final_V2.svg";
import resources from "assets/images/icons/resources.svg";
const SVG_IMAGES = {
  resources:resources,
  apartment: iconApartment,
  iconHome: iconHome,
  briefcase: iconBriefcase,
  dashboard: iconDashboard,
  file: iconFile,
  gavel: iconGavel,
  info: iconInfo,
  notifications: iconNotifications,
  search: iconSearch,
  server: iconServer,
  "star-box": iconStarBox,
  triangle: iconTriangle,
  "user-settings": iconUserSettings,
  user: iconUser,
  world: iconWorld,
  prison: iconPrison,
  "admin-profile": iconAdminProfile,
  "events-round": iconEventsRound,
  iconUpload,
  iconStaging,
  iconEdit,
  iconDownload,
  iconAdminPanel,
  iconAddProject,
  iconPlugins,
  iconLogout,
  iconuploadAllocation,
  iconDeliver,
};

export default SVG_IMAGES;
