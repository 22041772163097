import React from "react";
import { AuthorizationLayoutRoutes } from "routes";
import { SiteFooter } from "components/Layout";

const AuthorizationLayout = () => (
  <div className="authorization-layout">
    <main className="authorization-layout__main">
      <AuthorizationLayoutRoutes />
    </main>
    <SiteFooter />
  </div>
);

export default AuthorizationLayout;
